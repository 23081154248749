@mixin scrollbars($size) {
  // style for scroll bar
  &::-webkit-scrollbar {
    width: $size;
  }
  &::-webkit-scrollbar-track {
    width: $size;
    background-color: white;
    //box-shadow: inset 0px 2px 6px rgba(43, 135, 220, 0.2);
  }
  &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:hover {
    width: $size;
    background: #46484e !important;
    //box-shadow: 0px 0px 8px rgba(43, 135, 220, 0.15);
    border: 3px solid white;
    border-radius: 6px;
  }
}
