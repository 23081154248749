.orders-page {
  .ant-table-cell-fix-right-first {
    padding: 5px;
  }
  .actions-wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .actions-item {
    color: #1890ff;
    font-size: 12px;
    cursor: pointer;
    padding: 2px;
  }
  .ant-table-content {
    height: calc(100vh - 64px - 49px - 48px - 12px) !important;
    overflow: auto !important;
  }
  &.with-pagination {
    .ant-table-content {
      height: calc(100vh - 64px - 49px - 48px - 32px - 10px) !important;
    }
  }
  .ant-table-sticky-scroll {
    display: none !important;
  }
  .ant-table-cell {
    white-space: nowrap;
  }
  .ant-select {
    &:hover {
      .ant-select-selector {
        border: 1px solid #d9d9d9 !important;
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        border: 1px solid #d9d9d9 !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.85);
    }
    .ant-select-item-option-selected {
      display: none !important;
    }
  }
}

.order-actions {
  .ant-select-item-option-selected {
    display: none;
  }
}

.order-modal-form {
  display: flex;
  align-items: flex-end;
  .base-input {
    margin-bottom: 0;
    input {
      height: 32px;
    }
  }
  .base-select {
    margin-bottom: 0;
    width: 100%;
  }
  button {
    margin-left: 10px;
    transition: none;
    transition: background-color .3s;
  }
  &.order-modal-form-has-error {
    button {
      margin-bottom: 22px;
    }
  }
}