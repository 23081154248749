.simple-editor {
  margin-bottom: 20px;
  .rdw-editor-main {
    min-height: 300px;
    height: inherit;
    border: 1px solid #F1F1F1 !important;
    background: #fff;
    padding: 5px !important;
    border-radius: 2px !important;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease;
    &:hover {
      border-color: rgb(64, 169, 255) !important;
    }
    &:focus-within {
      border-color: rgb(64, 169, 255) !important;
      box-shadow: rgba(24, 144, 255, 0.2) 0px 0px 0px 2px !important;
    }
  }
  .rdw-editor-wrapper {
    max-width: 1200px;
  }
  &__error {
    .rdw-editor-main {
      box-shadow: none;
      border-color: rgb(255, 77, 79) !important;
      &:hover, &:focus-within {
        border-color: rgb(255, 77, 79) !important;
        box-shadow: none !important;
      }
    }
    &-message {
      color: #ff4d4f;
      font-size: 12px;
      margin-top: 3px;
      opacity: .9;
    }
  }
  &__title {
    margin-bottom: 5px;
    width: max-content;
    span {
      color: rgb(255, 77, 79);
      display: inline-block;
      margin-left: 3px;
    }
  }
}