.base-table {
  height: 100%;
  background: #fff;
  margin-bottom: 10px;
  overflow-y: auto;
  .actions {
    .ant-space-item {
      cursor: pointer;
    }
  }
  .small-table-image {
    max-width: 100px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
}
