.settings-item {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &__title {
    font-weight: bold;
  }
  &__value {

  }
}
