.content-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, .5);
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin {
  }
}
