.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  input[type="submit"] {
    cursor: pointer;
  }
  &__headline {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.login-page {
  .ant-spin-nested-loading {
    width: 100%;
  }
}