@import "./mixins";

html, body, #root, .app {
  height: 100%;
  font-family: sans-serif;
}

* {
  @include scrollbars(12px);
}

.page-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 555;
  top: 0;
  left: 0;
  bottom: 0;
  background: #fff;
}

.simple-headline {
  font-weight: 700;
  font-size: 28px;
}

.ant-layout {
  &-content {
    display: flex;
    flex-direction: column;
  }
}

.ant-empty {
  margin-top: 30px;
}

.base-error {
  display: block;
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 3px;
  opacity: .9;
}

.base-wrapper {
  width: 100%;
  &.mw-1200 {
    max-width: 1200px;
  }
  .ant-btn {
    margin-top: 15px;
  }
}

.skin-color-options {
  display: flex;
  align-items: center;
  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}

.ant-table-thead tr {
  position: sticky !important;
  top: 0;
  z-index: 55;
}
