.base-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  input, textarea {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 4px 11px;
    outline: none;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease;
    &:hover {
      border-color: rgb(64, 169, 255)
    }
    &:focus, &:focus-visible {
      border-color: rgb(64, 169, 255);
      box-shadow: rgba(24, 144, 255, 0.2) 0 0 0 2px
    }
  }
  textarea {
    height: 120px;
  }
  &__sub-title {
    font-size: 11px;
    font-style: italic;
    margin-top: -10px;
    margin-bottom: 5px;
  }
  label {
    margin-bottom: 5px;
    width: max-content;
    span {
      color: rgb(255, 77, 79);
      display: inline-block;
      margin-left: 3px;
    }
  }
  &__error-message {
    color: rgb(255, 77, 79);
    font-size: 12px;
    margin-top: 3px;
    opacity: .9;
  }
  &__error {
    input, textarea {
      border-color: rgb(255, 77, 79) !important;
      box-shadow: none !important;
    }
  }
}
