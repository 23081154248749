@import "./../../assets/styles/mixins";

.home-layout {
  min-height: 100vh ;
  .ant-layout-header {
    display: flex;
    justify-content: space-between;
  }
  .logout {
    color: #fff;
    img {
      margin-right: 5px;
    }
    button {
      background: none;
      border: none;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
  &.withoutScroll {
    height: calc(100vh - 64px);
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    text-align: center;
  }
  .anticon-right {
    margin-left: 0 !important;
  }
}

.site-layout {
  position: relative;
}

.ant-menu-root {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
