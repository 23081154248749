.order-image-preview {
  max-width: 100px;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
}

.mug-image-tabs {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  span {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    &:first-child {
      margin-right: 20px;
    }
    &.active {
      color: #3a8eff;
      cursor: default;
      position: relative;
      &:before {
        position: absolute;
        content: "";
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #3a8eff;
      }
    }
  }
}