.base-date-time-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &__label {
    display: inline-block;
    margin-bottom: 5px;
    width: max-content;
    span {
      color: #ff4d4f;
      display: inline-block;
      margin-left: 3px;
    }
  }
}
