.login-page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #EBEEF6;
  border-radius: 4px;
  margin: 0 auto;
  width: 100%;
  &__wrapper {
    background: #fff;
    padding: 70px;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
  }
}
