.img-uploader  {
  .ant-upload-select .ant-upload {
    flex-direction: column;
  }
  .ant-upload-list-item-error {
    border-color: #d9d9d9 !important;
  }
  &-error {
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      border-color: #ff4d4f;
    }
    .img-uploader__details {
      margin-top: -2px;
    }

    .ant-upload-list-item-error {
      border-color: #ff4d4f !important;
    }
  }
  &__label {
    margin-bottom: 5px;
    width: max-content;
    span {
      color: #ff4d4f;
      display: inline-block;
      margin-left: 3px;
    }
  }
}

.ant-upload-list-picture-card-container .ant-tooltip {
  display: none !important;
}


